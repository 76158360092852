import {
    Component,
    Input,
    Output,
    OnInit,
    OnChanges,
    EventEmitter,
    SimpleChanges,
    ChangeDetectorRef,
    ChangeDetectionStrategy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule, FormBuilder, FormGroup } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TranslocoModule } from '@ngneat/transloco';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { ObjectType } from 'app/common/supabase-models/object-type';
import { Object } from 'app/common/supabase-models/object';
import { ObjectTypeService } from 'app/common/supabase-services/object-type.service';
import { ObjectService } from 'app/common/supabase-services/object.service';

@Component({
    selector: 'app-select-object',
    templateUrl: './select-object.component.html',
    styleUrls: ['./select-object.component.scss'],
    standalone: true,
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        MatFormFieldModule,
        MatInputModule,
        MatSelectModule,
        MatButtonModule,
        MatCheckboxModule,
        TranslocoModule
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SelectObjectComponent implements OnInit, OnChanges {
    @Input() ownerId: string; // Owner ID for filtering objects
    @Input() selectedIds: number[] = []; // IDs of already selected objects
    @Input() entityId: number | null = null; // Pre-selected object ID
    @Input() label: string = 'Select Entity'; // Label for object selection dropdown
    @Input() btnLabel: string = 'Save'; // Button label for save
    @Input() disabled: boolean = false; // Disable interaction
    @Input() allowAdhoc: boolean = false; // Allow adding new objects
    @Input() onChangeOnly: boolean = false; // Save on change only
    @Input() selectedObjectTypeId: number | null = null; // default object type value
    @Output() onSaveObject = new EventEmitter<any>(); // Emit when saving an object

    objectTypes$: Observable<ObjectType[]>; // Observable for object types
    objects$: Observable<Object[]>; // Observable for objects
    selectedObjectType: ObjectType | null = null; // Selected object type
    selectedObject: Object | null = null; // Selected object
    activateEntityForm: boolean = false; // Flag for ad-hoc object creation
    form: FormGroup; // Form group for dynamic form creation
    currentObjectType: number | null = null; // Tracks the current object type being created

    constructor(
        private objectTypeService: ObjectTypeService,
        private objectService: ObjectService,
        private formBuilder: FormBuilder,
        private changeDetectorRef: ChangeDetectorRef
    ) {}

    ngOnInit() {
        if (this.selectedObjectTypeId) {
            this.selectedObjectType = { id: this.selectedObjectTypeId } as ObjectType;
            this.loadObjectsByType();
        } else {
            this.loadObjectTypes();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes.ownerId || changes.entityId) {
            if (!this.selectedObjectTypeId) {
                this.loadObjectTypes();
            }
        }
    }

    // Load available object types
    loadObjectTypes() {
        this.objectTypes$ = this.objectTypeService.getAll().pipe(
            tap((objectTypes) => {
                this.changeDetectorRef.markForCheck();
            })
        );
    }

    // Load objects for the selected object type
    loadObjectsByType() {
        if (this.selectedObjectType) {
            this.objects$ = this.objectService
                .getAllByObjectTypeAndOwner(this.selectedObjectType.id, this.ownerId)
                .pipe(
                    tap((objects) => {
                        objects.sort((a, b) => {
                            const nameA = (a.name || '').toLowerCase();
                            const nameB = (b.name || '').toLowerCase();
                            return nameA.localeCompare(nameB);
                        });

                        // Pre-select object if entityId is provided
                        if (this.entityId) {
                            this.selectedObject = objects.find((object) => object.id === this.entityId) || null;
                        }
                        this.changeDetectorRef.markForCheck();
                    })
                );
        }
    }

    // Triggered when an object type is selected
    onSelectObjectType(objectType: ObjectType) {
        this.selectedObjectType = objectType;
        this.selectedObject = null; // Reset selected object
        this.activateEntityForm = false;
        this.loadObjectsByType();
    }

    // Triggered when an object is selected
    onSelectObject(selectedObject: Object | number): void {
        if (selectedObject === 0 && this.allowAdhoc) {
            this.activateEntityForm = true;
            this.createForm();
        } else if (typeof selectedObject !== 'number') {
            this.selectedObject = selectedObject;
        }
    }

    // Create form for ad-hoc object creation
    createForm() {
        if (!this.selectedObjectType) {
            console.error('Object type must be selected before creating a form.');
            return;
        }

        if (this.selectedObjectType.id === 1) {
            this.currentObjectType = 1;
            this.form = this.formBuilder.group({
                make: [''],
                model: [''],
                year: [''],
                vin: [''],
                color: [''],
                engine_size: [''],
                registration_number: [''],
            });
        } else if (this.selectedObjectType.id === 2) {
            this.currentObjectType = 2;
            this.form = this.formBuilder.group({
                address: [''],
                size_in_sqft: [''],
                number_of_rooms: [''],
                year_built: [''],
                architect: [''],
                has_fire_alarm: [false],
                has_security_system: [false],
            });
        } else {
            console.error('Unsupported object type ID:', this.selectedObjectType.id);
        }
    }

    saveObject() {
        if (this.activateEntityForm && this.form.valid && this.selectedObjectType) {
            const objectData = {
                object_type_id: this.selectedObjectType.id,
                name:
                    this.currentObjectType === 1
                        ? `${this.form.get('make')?.value} ${
                              this.form.get('model')?.value
                          } ${this.form.get('year')?.value}`
                        : `${this.form.get('address')?.value} - ${
                              this.form.get('size_in_sqft')?.value
                          } sqft, ${this.form.get('number_of_rooms')?.value} rooms`,
                dynatt: this.form.value,
                owner_id: this.ownerId,
            };

            this.objectService.saveObject(objectData).subscribe({
                next: (response) => {
                    this.selectedObject = response;
                    this.loadObjectsByType();
                    this.activateEntityForm = false;
                    this.currentObjectType = null;
                    this.changeDetectorRef.markForCheck();
                },
                error: (err) => console.error('Error saving object:', err.message),
            });
        }
    }

    onSave() {
        this.onSaveObject.emit(this.selectedObject);
    }

    closeForm() {
        this.activateEntityForm = false;
        this.currentObjectType = null;
        this.selectedObject = null;
        if (this.form) this.form.reset();
    }

    trackById(index: number, item: any): number {
        return item.id;
    }

    compareObjTypeWithFunction(item1: ObjectType, item2: ObjectType) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }

    compareObjWithFunction(item1: Object, item2: Object) {
        return item1 && item2 ? item1.id === item2.id : item1 === item2;
    }
}
