import { Route } from '@angular/router';
import { initialDataResolver } from 'app/app.resolvers';
import { AuthGuard } from 'app/core/auth/guards/auth.guard';
import { NoAuthGuard } from 'app/core/auth/guards/noAuth.guard';
import { LayoutComponent } from 'app/layout/layout.component';
import { AdminGuard } from './core/auth/guards/admin.guard';
import { documentUploadsRoutes } from './modules/admin/apps/document-uploads/document-uploads.routes';


// @formatter:off
/* eslint-disable max-len */
/* eslint-disable @typescript-eslint/explicit-function-return-type */
export const appRoutes: Route[] = [
    // Redirect empty path to '/example'
    { path: '', pathMatch: 'full', redirectTo: 'dashboard' },

    // Redirect signed-in user to the '/dashboard'
    //
    // After the user signs in, the sign-in page will redirect the user to the 'signed-in-redirect'
    // path. Below is another redirection for that path to redirect the user to the desired
    // location. This is a small convenience to keep all main routes together here on this file.
    { path: 'signed-in-redirect', pathMatch: 'full', redirectTo: 'dashboard' },

    // Auth routes for guests
    {
        path: '',
        canActivate: [NoAuthGuard],
        canActivateChild: [NoAuthGuard],
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'confirmation-required',
                loadChildren: () =>
                    import(
                        'app/modules/auth/confirmation-required/confirmation-required.routes'
                    ),
            },
            {
                path: 'forgot-password',
                loadChildren: () => import('app/modules/auth/forgot-password/forgot-password.routes')
            },
            {
                path: 'reset-password',
                loadChildren: () => import('app/modules/auth/reset-password/reset-password.routes')
            },
            {
                path: 'sign-in',
                loadChildren: () => import('app/modules/auth/sign-in/sign-in.routes'),
            },
            {
                path: 'sign-up',
                loadChildren: () => import('app/modules/auth/sign-up/sign-up.routes'),
            },
        ],
    },

    // Auth routes for authenticated users
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        data: { layout: 'empty' },
        children: [
            {
                path: 'sign-out',
                loadChildren: () => import('app/modules/auth/sign-out/sign-out.routes'),
            },
            {
                path: 'unlock-session',
                loadChildren: () => import('app/modules/auth/unlock-session/unlock-session.routes'),
            },
        ],
    },

    // Landing routes
    {
        path: '',
        component: LayoutComponent,
        data: {
            layout: 'empty',
        },
        children: [
            {
                path: 'home',
                loadChildren: () => import('app/modules/landing/home/home.routes'),
            },
        ],
    },

    // Admin routes
    {
        path: '',
        canActivate: [AuthGuard],
        canActivateChild: [AuthGuard],
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            // Dashboards
            {
                path: 'dashboard',
                loadChildren: () => import('app/modules/admin/dashboards/finance/finance.routes'),
            },
            {
                path: 'example',
                loadChildren: () => import('app/modules/admin/example/example.routes'),
            },
            {
                path: 'policies',
                // loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.routes'),
                loadChildren: () => import('app/modules/admin/apps/user-insurances/user-insurances.routes'),
            },
            {
                path: 'settings',
                loadChildren: () => import('app/modules/admin/pages/settings/settings.routes'),
            },
            {
                path: 'profile',
                loadChildren: () => import('app/modules/admin/pages/settings/account/account.routes'),
            },
            {
                path: 'people-objects',
                loadChildren: () => import('app/modules/admin/apps/scrumboard/scrumboard.routes'),
            },
            {
                path: 'help',
                loadChildren: () => import('app/modules/admin/apps/help-center/help-center.routes'),
            },
            {
                path: 'insurers',
                // loadChildren: () => import('app/modules/admin/apps/ecommerce/ecommerce.routes'),
                loadChildren: () => import('app/modules/admin/apps/user-insurances/user-insurances.routes'),
            },
        ],
    },

    {
        path: 'admin',
        canActivate: [AuthGuard], // TODO: replace this with AdminGuard
        canActivateChild: [AdminGuard], // TODO: replace this with AdminGuard
        component: LayoutComponent,
        resolve: {
            initialData: initialDataResolver,
        },
        children: [
            {
                path: 'insurers',
                loadChildren: () => import('app/modules/admin/apps/insurers/insurers.routes'),
            },
            {
                path: 'insurances',
                loadChildren: () => import('app/modules/admin/apps/insurances/insurerances.routes'),
            },
            {
                path: 'products', 
                loadChildren: () => import('app/modules/admin/apps/products/products.routes')
            },
            {
                path: 'accounts', 
                loadChildren: () => import('app/modules/admin/apps/accounts/accounts.routes')
            },
            {
                path: 'persons', 
                loadChildren: () => import('app/modules/admin/apps/persons/persons.routes')
            },
            {
                path: 'objects', 
                loadChildren: () => import('app/modules/admin/apps/objects/objects.routes')
            },
            {
                path: 'tasks',
                children: documentUploadsRoutes,
            },
        ],
    },
];
