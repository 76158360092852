import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, ReplaySubject, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { PersonWithSetting } from '../supabase-models/person-with-setting';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class PersonWithSettingService {
    private supabase: SupabaseClient;
    private _personWithSetting: ReplaySubject<PersonWithSetting> = new ReplaySubject<PersonWithSetting>(1);

    constructor(private supabaseClientService: SupabaseClientService) {
        // Get the Supabase client from the SupabaseClientService
        this.supabase = this.supabaseClientService.getClient();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Setter & getter for person with settings
     *
     * @param value
     */
    set personWithSetting(value: PersonWithSetting) {
        // Store the value
        this._personWithSetting.next(value);
    }

    get personWithSetting$(): Observable<PersonWithSetting> {
        return this._personWithSetting.asObservable();
    }

    // -----------------------------------------------------------------------------------------------------
    // @ CRUD methods
    // -----------------------------------------------------------------------------------------------------

    /**
     * Retrieve person with settings by user ID and update observable
     * @param userId The ID of the user
     */
    getPersonWithSettingsById(userId: string): Observable<PersonWithSetting> {
        return from(
            this.supabase
                .from('person_with_settings')
                .select('*')
                .eq('user_id', userId)
                .single()
        ).pipe(
            map((response) => {
                const personWithSetting = response.data as PersonWithSetting;
                this.personWithSetting = personWithSetting; // Update the observable
                return personWithSetting;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    getPersonWithSettingsByUserId(userId: string): Observable<PersonWithSetting> {
        return from(
            this.supabase
                .from('person_with_settings')
                .select('*')
                .eq('user_id', userId)
                .single()
        ).pipe(
            map((response) => {
                const personWithSetting = response.data as PersonWithSetting;
                return personWithSetting;
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

     /**
     * Retrieve all records from the person_with_settings view
     */
     getAll(): Observable<PersonWithSetting[]> {
        return from(
            this.supabase
                .from('person_with_settings')
                .select('*')
        ).pipe(
            map((response) => {
                if (response.data) {
                    return response.data as PersonWithSetting[];
                }
                throw new Error('Failed to fetch person with settings');
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }
}
