import { ApplicationConfig, importProvidersFrom } from '@angular/core';
import { provideHttpClient } from '@angular/common/http';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
    PreloadAllModules,
    provideRouter,
    withInMemoryScrolling,
    withPreloading,
} from '@angular/router';
import { provideFuse } from '@fuse';
import { appRoutes } from 'app/app.routes';
import { provideIcons } from 'app/core/icons/icons.provider';
import { provideTransloco } from 'app/core/transloco/transloco.provider';
import { mockApiServices } from 'app/mock-api';

// Import Angular Formly and Angular Material modules
import { FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';

// Import custom field components
import { PanelWrapperComponent } from 'app/common/components/dynamic-form/panel-wrapper.component';
import { DatepickerTypeComponent } from 'app/common/components/dynamic-form/datepicker.type';
import { RepeatTypeComponent } from 'app/common/components/dynamic-form/repeat.type';
import { YesNoTypeComponent } from 'app/common/components/dynamic-form/yesno.type';

// Import Firebase modules
import { getAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { environment } from 'environments/environment';

// Import MarkdownModule
import { MarkdownModule } from 'ngx-markdown';

export const appConfig: ApplicationConfig = {
    providers: [
        provideAnimations(),
        provideHttpClient(),
        provideRouter(
            appRoutes,
            withPreloading(PreloadAllModules),
            withInMemoryScrolling({ scrollPositionRestoration: 'enabled' })
        ),
        // Transloco Config
        provideTransloco(),

        // Fuse Configuration
        provideIcons(),
        provideFuse({
            mockApi: {
                delay: 0,
                services: mockApiServices,
            },
            fuse: {
                layout: 'centered',
                scheme: 'light',
                screens: {
                    sm: '600px',
                    md: '960px',
                    lg: '1280px',
                    xl: '1440px',
                },
                theme: 'theme-default',
                themes: [
                    { id: 'theme-default', name: 'Default' },
                    { id: 'theme-brand', name: 'Brand' },
                    { id: 'theme-teal', name: 'Teal' },
                    { id: 'theme-rose', name: 'Rose' },
                    { id: 'theme-purple', name: 'Purple' },
                    { id: 'theme-amber', name: 'Amber' },
                ],
            },
        }),
        // Import providers from modules
        importProvidersFrom(
            // Angular Formly Module with custom types and wrappers
            FormlyModule.forRoot({
                types: [
                    { name: 'datepicker', component: DatepickerTypeComponent },
                    { name: 'repeat', component: RepeatTypeComponent },
                    { name: 'yesno', component: YesNoTypeComponent },
                ],
                wrappers: [
                    { name: 'panel', component: PanelWrapperComponent },
                ],
            }),
            FormlyMaterialModule,
            // Angular Material Modules
            MatFormFieldModule,
            MatInputModule,
            MatCheckboxModule,
            MatSelectModule,
            MatDatepickerModule,
            MatButtonModule,
            // Firebase Modules
            provideFirebaseApp(() => initializeApp(environment.firebase)),
            provideAuth(() => getAuth()),
            provideFirestore(() => getFirestore()),
            provideFunctions(() => getFunctions()),
            provideStorage(() => getStorage()),
            // Markdown Module
            MarkdownModule.forRoot()
        )
    ],
};