import { CommonModule } from '@angular/common';
import {
    Component,
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Input,
    Output,
    EventEmitter, 
    OnChanges,
    OnDestroy,
    OnInit,
    SimpleChanges,
} from '@angular/core';
import {
    AbstractControl,
    FormBuilder,
    FormGroup,
    ReactiveFormsModule,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { DateAdapter, MatNativeDateModule } from '@angular/material/core';
import { MatDialog, MatDialogModule } from '@angular/material/dialog';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatButtonModule } from '@angular/material/button';
import { MatSelectModule } from '@angular/material/select';
import { MatInputModule } from '@angular/material/input';
import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Decimal } from 'decimal.js';

import { FormlyMaterialModule } from '@ngx-formly/material';
import { FormlyModule } from '@ngx-formly/core';
import cloneDeep from 'lodash-es/cloneDeep';

import { SelectProductTypeComponent } from 'app/common/components/select-product-type/select-product-type.component';
import { LoadingOverlayComponent } from 'app/common/components/loading-overlay/loading-overlay.component';
import { SelectInsurerComponent } from 'app/common/components/select-insurer/select-insurer.component';
import { SelectProductComponent } from 'app/common/components/select-product/select-product.component';
import { SelectPersonComponent } from 'app/common/components/select-person/select-person.component';
import { SelectObjectComponent } from 'app/common/components/select-object/select-object.component';
import { DynamicFormComponent } from 'app/common/components/dynamic-form/dynamic-form.component';
import { SwissLuxonDateAdapter } from 'app/common/services/swiss-luxondateadapter';

import { InsuranceProductExpandedView } from 'app/common/supabase-models/insurance-product-expanded-view';
import { PolicyExtractionView } from 'app/common/supabase-models/policy-extraction-view';
import { InsuranceGroupedView } from 'app/common/supabase-models/insurance-grouped-view';
import { PersonWithSetting } from 'app/common/supabase-models/person-with-setting';
import { Insurance, Premium } from 'app/common/supabase-models/insurance';
import { LangCode } from 'app/common/supabase-models/common';
import { Person } from 'app/common/supabase-models/person';
import { Object } from 'app/common/supabase-models/object';

import { InsuranceService } from 'app/common/supabase-services/insurance.service';
import { PersonWithSettingService } from 'app/common/supabase-services/person-with-setting.service';

@Component({
    selector: 'app-add-insurance-form',
    templateUrl: './add-insurance-form.component.html',
    styleUrls: ['./add-insurance-form.component.scss'],
    standalone: true,
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        MatButtonModule,
        MatDatepickerModule,
        MatDialogModule,
        MatFormFieldModule,
        MatInputModule,
        MatNativeDateModule,
        MatSelectModule,
        TranslocoModule,
        FormlyMaterialModule,
        FormlyModule,
        DynamicFormComponent,
        LoadingOverlayComponent,
        SelectInsurerComponent,
        SelectPersonComponent,
        SelectObjectComponent,
        SelectProductComponent,
        SelectProductTypeComponent,
    ],
    providers: [
        {
            provide: DateAdapter,
            useClass: SwissLuxonDateAdapter,
        },
    ],
})
export class AddInsuranceFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() policyExtraction!: PolicyExtractionView;
    @Input() existingInsurance: InsuranceGroupedView | null = null;
    @Input() preSelectedCoveredEntityId: number | null = null;
    @Input() selectedCoveredEntityType: 'person' | 'object' | null = null;
    @Input() selectedObjectTypeId: number | null = null;
    @Output() formCompleted = new EventEmitter<void>();

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private selectedLanguage: LangCode = 'en';
    isLoading: boolean = false;
    insuranceForm: FormGroup;
    optionsForm: FormGroup;
    premiumForm: FormGroup;
    currentUser: PersonWithSetting;

    selectedProductTypeId: number | null = null;
    selectedInsurerId: number | null = null;
    selectedInsuranceProduct: InsuranceProductExpandedView | null = null;
    selectedPolicyHolderId: number | null = null;
    selectedCoveredEntityId: number | null = null;

    existingOptions: any;

    constructor(
        private fb: FormBuilder,
        private matDialog: MatDialog,
        private translocoService: TranslocoService,
        private changeDetectorRef: ChangeDetectorRef,
        private personWithSettingService: PersonWithSettingService,
        private insuranceService: InsuranceService
    ) {
        this.translocoService.langChanges$
        .pipe(takeUntil(this._unsubscribeAll))
        .subscribe((lang) => {
            this.selectedLanguage = lang.toLowerCase() as LangCode;
        });
    }

    ngOnInit(): void {
        this.initializeForm();

        this.personWithSettingService.personWithSetting$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user) => {
                if (user.user_id !== this.currentUser?.user_id) {
                    this.currentUser = user;
                }
            });

        if (this.preSelectedCoveredEntityId) {
            this.selectedCoveredEntityId = this.preSelectedCoveredEntityId;
        }

        if (this.existingInsurance) {
            this.prefillFromExistingInsurance(this.existingInsurance);
        }
        
        if (!this.existingInsurance && this.policyExtraction) {
            this.prefillFromPolicyExtraction(this.policyExtraction);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.existingInsurance && changes.existingInsurance.currentValue) {
            if (!this.insuranceForm) {
                this.initializeForm();
            }
            this.prefillFromExistingInsurance(changes.existingInsurance.currentValue);
        }
    }

    private initializeForm() {
        this.premiumForm = this.fb.group({
            periodicityNumMonths: [12, [Validators.required]],
            grossCents: [this.convertCentsToDecimal(0), [Validators.required, this.twoDigitDecimalValidator()]],
            netCents: [this.convertCentsToDecimal(0), [Validators.required, this.twoDigitDecimalValidator()]],
            discounts: this.fb.group({
                associationDiscount: this.createDiscountFormGroup(),
                familyDiscount: this.createDiscountFormGroup(),
                loyaltyDiscount: this.createDiscountFormGroup(),
                environmentalDiscount: this.createDiscountFormGroup(),
                otherDiscounts: this.createDiscountFormGroup(),
            }),
        });

        this.insuranceForm = this.fb.group({
            captured_status: ['pending', Validators.required],
            insurance_product_id: [null, Validators.required],
            individual_contractual_overrides: [''],
            owner_id: ['', Validators.required],
            creator_id: ['', Validators.required],
            policy_holder_id: [null, Validators.required],
            covered_person_id: [null],
            covered_object_id: [null],
            policy_extraction_id: [null, Validators.required],
            payment_periodicity: ['', Validators.required],
            effective_date: ['', Validators.required],
            expiration_date: ['', Validators.required],
            policy_date: ['', Validators.required],
            policy_pdf_url: ['', Validators.required],
            premium: this.premiumForm,
            options_json: [null],
            options_canonical: [''],
            details_notes: [''],
        });

        this.optionsForm = new FormGroup({});
    }

    private createDiscountFormGroup(): FormGroup {
        return this.fb.group({
            percentage: [0],
            amountCents: [this.convertCentsToDecimal(0), [this.twoDigitDecimalValidator()]],
        });
    }

    private prefillFromPolicyExtraction(policyExtraction: PolicyExtractionView) {
        const contractData = policyExtraction.contract_data;

        const formValues = {
            owner_id: policyExtraction.user_id,
            creator_id: this.currentUser?.user_id || '',
            policy_extraction_id: policyExtraction.policy_extraction_id,
            policy_pdf_url: policyExtraction.path,
            payment_periodicity: contractData?.payment_periodicity || '',
            policy_date: contractData?.contract_date || '',
            effective_date: contractData?.effective_date || '',
            expiration_date: contractData?.expiration_date || '',
            policy_holder_id: contractData?.holder_id || null,
            covered_person_id: null,
            covered_object_id: null,
        };
        
        if (this.selectedCoveredEntityType === 'person') {
            formValues.covered_person_id = this.preSelectedCoveredEntityId || null;
        } else if (this.selectedCoveredEntityType === 'object') {
            formValues.covered_object_id = this.preSelectedCoveredEntityId || null;
        }

        this.insuranceForm.patchValue(formValues);
        this.selectedInsurerId = contractData?.insurer_id ? contractData.insurer_id : null;
        this.selectedPolicyHolderId = contractData?.holder_id;
        this.changeDetectorRef.markForCheck();
    }

    private prefillFromExistingInsurance(existingInsurance: InsuranceGroupedView): void {
        if (!this.insuranceForm) {
            this.initializeForm();
        }

        if (existingInsurance.premium) {
            const premium = cloneDeep(existingInsurance.premium);
            premium.grossCents = this.convertCentsToDecimal(premium.grossCents);
            premium.netCents = this.convertCentsToDecimal(premium.netCents);

            if (premium.discounts) {
                const newDiscounts = {};
                for (const [key, discount] of Object.entries(premium.discounts)) {
                    newDiscounts[key] = discount
                        ? { ...discount, amountCents: this.convertCentsToDecimal(discount.amountCents) }
                        : discount;
                }
                premium.discounts = newDiscounts as Premium['discounts'];
            }

            this.premiumForm.patchValue(premium);
        }

        const formValues = {
            captured_status: existingInsurance.captured_status,
            insurance_product_id: existingInsurance.insurance_product_id,
            individual_contractual_overrides: existingInsurance.individual_contractual_overrides,
            owner_id: existingInsurance.owner_id,
            creator_id: existingInsurance.creator_id,
            policy_extraction_id: existingInsurance.policy_extraction_id,
            payment_periodicity: existingInsurance.payment_periodicity,
            effective_date: existingInsurance.effective_date,
            expiration_date: existingInsurance.expiration_date,
            policy_date: existingInsurance.policy_date,
            policy_pdf_url: existingInsurance.policy_pdf_url,
            details_notes: existingInsurance.details_notes,
            policy_holder_id: existingInsurance.policy_holder_id,
            covered_person_id: existingInsurance?.covered_person_id || null,
            covered_object_id: existingInsurance?.covered_object_id || null,
        };

        this.insuranceForm.patchValue(formValues);

        this.selectedProductTypeId = existingInsurance.product_insurance_product_type_id;
        this.selectedInsurerId = existingInsurance.product_brand_insurer_id;

        this.selectedPolicyHolderId = existingInsurance.policy_holder_id;
        this.selectedCoveredEntityId = existingInsurance?.covered_person_id || existingInsurance?.covered_object_id;

        this.existingOptions = existingInsurance.options_json[this.selectedLanguage || 'en'];;

        this.selectedInsuranceProduct = {
            insurance_product_id: existingInsurance.insurance_product_id,
            product_default_name: existingInsurance.product_default_name,
            insurance_product_type_id: existingInsurance.product_insurance_product_type_id,
            brand_insurer_id: existingInsurance.product_brand_insurer_id,
            options: existingInsurance.product_options,
            internal_notes: existingInsurance.product_internal_notes,
            options_json_schema: existingInsurance.product_options_json_schema,
            options_json_schema_multilang: existingInsurance.product_options_json_schema_multilang,
            options_ui_schema: existingInsurance.product_options_ui_schema,
            description_md: existingInsurance.product_description_md,
            tnc_vector_name: existingInsurance.product_tnc_vector_name,
            product_name_de: existingInsurance.product_name_de,
            product_name_en: existingInsurance.product_name_en,
            product_name_fr: existingInsurance.product_name_fr,
            product_name_it: existingInsurance.product_name_it,
            product_created_at: existingInsurance.product_created_at,
            product_updated_at: existingInsurance.product_updated_at,
            product_link: existingInsurance.product_link,
            product_type_default_name: existingInsurance.product_type_default_name,
            product_type_name_en: existingInsurance.product_type_name_en,
            product_type_name_de: existingInsurance.product_type_name_de,
            product_type_name_fr: existingInsurance.product_type_name_fr,
            product_type_name_it: existingInsurance.product_type_name_it,
            insurer_default_name:
                existingInsurance.insurer_name_en ||
                existingInsurance.insurer_name_de ||
                existingInsurance.insurer_name_fr ||
                existingInsurance.insurer_name_it,
            insurer_logo: existingInsurance.insurer_logo,
            insurer_is_active: true,
        } as InsuranceProductExpandedView;

        this.changeDetectorRef.markForCheck();
    }

    onProductTypeSelected(productTypeId: number | null) {
        this.selectedProductTypeId = productTypeId;
        this.selectedInsuranceProduct = null;
        this.changeDetectorRef.markForCheck();
    }

    onInsurerSelected(insurerId: number | null) {
        this.selectedInsurerId = insurerId;
        this.selectedInsuranceProduct = null;
        this.changeDetectorRef.markForCheck();
    }

    onProductSelected(product: InsuranceProductExpandedView | null) {
        this.selectedInsuranceProduct = product;
        if (product) {
            this.insuranceForm.patchValue({
                insurance_product_id: product.insurance_product_id,
            });

            if (!this.existingInsurance) {
                this.optionsForm = new FormGroup({});
            }

            if (product.options && !this.existingInsurance) {
                this.optionsForm.patchValue(product.options);
            }
        }
        this.changeDetectorRef.detectChanges();
    }

    onPolicyHolderSelected(policyHolder: Person) {
        this.insuranceForm.patchValue({ policy_holder_id: policyHolder.id });
    }

    onCoveredEntitySelected(coveredEntity: Person | Object, entityType: 'person' | 'object') {
        if (entityType === 'person') {
            this.insuranceForm.patchValue({ covered_person_id: coveredEntity.id });
        } else if (entityType === 'object') {
            this.insuranceForm.patchValue({ covered_object_id: coveredEntity.id });
        }
    }

    onSubmit(): void {
        if (this.insuranceForm.valid) {
            const insuranceData = this.prepareInsuranceData();
    
            this.isLoading = true;
            const loadingOverlayRef = this.matDialog.open(LoadingOverlayComponent, {
                data: {
                    isLoading: true,
                    loadingText: this.translocoService.translate(
                        this.existingInsurance
                            ? 'admin.docUploads.updatingInsurance'
                            : 'admin.docUploads.creatingInsurance'
                    ),
                },
                disableClose: true,
            });
    
            const request$ = this.existingInsurance
                ? this.insuranceService.update(
                      this.existingInsurance.insurance_id,
                      insuranceData
                  )
                : this.insuranceService.create(insuranceData);
    
            request$.pipe(takeUntil(this._unsubscribeAll)).subscribe({
                next: () => {
                    this.isLoading = false;
                    loadingOverlayRef.componentInstance.data = {
                        isLoading: false,
                        successText: this.translocoService.translate(
                            this.existingInsurance
                                ? 'admin.docUploads.insuranceUpdated'
                                : 'admin.docUploads.insuranceCreated'
                        ),
                    };

                    this.formCompleted.emit();
                },
                error: () => {
                    this.isLoading = false;
                    loadingOverlayRef.componentInstance.data = {
                        isLoading: false,
                        loadingText: '',
                        errorText: this.translocoService.translate(
                            'admin.docUploads.errorOccurred'
                        ),
                    };
                },
            });
        }
    }

    private prepareInsuranceData(): any {
        const insuranceData: Insurance = this.insuranceForm.value;
    
        // Convert premium data to cents
        if (insuranceData.premium) {
            insuranceData.premium.grossCents = this.sanitizeAndConvertToCents(insuranceData.premium.grossCents);
            insuranceData.premium.netCents = this.sanitizeAndConvertToCents(insuranceData.premium.netCents);
            Object.values(insuranceData.premium.discounts).forEach((discount) => {
                if (discount) {
                    discount.amountCents = this.sanitizeAndConvertToCents(discount.amountCents);
                }
            });
        }
    
        if (this.optionsForm.valid && this.selectedInsuranceProduct?.options_json_schema_multilang) {
            const { optionsJson, optionsCanonical } = this.buildOptionsJsonAndCanonical(
                this.optionsForm.value,
                this.selectedInsuranceProduct.options_json_schema_multilang,
                this.selectedLanguage
            );
    
            insuranceData.options_json = optionsJson;
            insuranceData.options_canonical = optionsCanonical;
        }
    
        return insuranceData;
    }
    
    private buildOptionsJsonAndCanonical(
        formValue: any,
        schemaMultilang: any,
        selectedLanguage: string
    ): { optionsJson: Record<string, any>; optionsCanonical: string } {
        const optionsJson: Record<string, any> = {};
        const canonicalEntries: string[] = [];
    
        for (const lang in schemaMultilang) {
            const localizedSchema = schemaMultilang[lang];
            const referenceSchema = schemaMultilang[selectedLanguage];
            optionsJson[lang] = this.mapFormToSchemaByLang(formValue, localizedSchema.properties, referenceSchema.properties);
    
            // Generate canonical entries for the current language
            this.generateCanonicalEntries(
                optionsJson[lang],
                [],
                lang,
                canonicalEntries
            );
        }
    
        return {
            optionsJson,
            optionsCanonical: canonicalEntries.join("\n"),
        };
    }
    
    private mapFormToSchemaByLang(
        formValue: any,
        targetSchema: any,
        referenceSchema: any
    ): any {
        const result: Record<string, any> = {};
    
        for (const key in targetSchema) {
            // Ensure the formValue contains the key
            if (formValue[key] !== undefined) {
                const targetProperty = targetSchema[key];
                const referenceProperty = referenceSchema[key];
    
                // If the property is an object, recurse into its properties
                if (targetProperty.type === 'object') {
                    result[key] = this.mapFormToSchemaByLang(
                        formValue[key],
                        targetProperty.properties,
                        referenceProperty.properties
                    );
                } else if (targetProperty.type === 'string' && targetProperty.enum) {
                    // Map string values using enum index
                    const index = referenceProperty.enum.indexOf(formValue[key]);
                    if (index !== -1) {
                        result[key] = targetProperty.enum[index];
                    }
                } else {
                    // For boolean or other types, assign the same value
                    result[key] = formValue[key];
                }
            }
        }
    
        return result;
    }
    
    private generateCanonicalEntries(
        obj: any,
        path: string[],
        language: string,
        canonicalEntries: string[]
    ): void {
        if (typeof obj === 'object' && obj !== null) {
            Object.keys(obj).forEach((key) => {
                this.generateCanonicalEntries(
                    obj[key],
                    [...path, key],
                    language,
                    canonicalEntries
                );
            });
        } else {
            // Handle leaf nodes (values)
            const fullPath = [...path.map((p) => `"${p}"`)].join(".");
            canonicalEntries.push(`${language}:po.${fullPath} = "${obj}"`);
        }
    }

    private sanitizeAndConvertToCents(value: string | number): number {
        if (!value) return 0; // Default to 0 if null or undefined
    
        const numericValue = parseFloat(value.toString().replace(/'/g, '')); // Remove formatting
        return isNaN(numericValue) ? 0 : Math.round(numericValue * 100); // Multiply by 100 for cents
    }

    private convertCentsToDecimal(value: string | number): any {
        const cents = new Decimal(value || 0);
        const decimalValue = cents.dividedBy(100).toFixed(2);
    
        // Format the value with a single quote as a thousand separator
        return decimalValue.replace(/\B(?=(\d{3})+(?!\d))/g, "'");
    }

    private twoDigitDecimalValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const value = control.value?.toString().replace(/'/g, '');
            if (!value) return null;
    
            const valid = /^\d+(\.\d{0,2})?$/.test(value);
            return valid ? null : { invalidDecimal: { value: control.value } };
        };
    }
    
    selectAll(event: any): void {
        event.target.select();
    }

    formatCentsValue(formControlName: string, value: number): void {
        // Convert the number to a string, if it's not already.
        const stringValue = value.toString();
    
        // Parse the string to a float to ensure it's a valid number.
        const numericValue = parseFloat(stringValue.replace(/'/g, ''));
    
        // If the value is not a valid number, set to '0.00'.
        const formattedValue = isNaN(numericValue)
            ? '0.00'
            : numericValue
                  .toFixed(2) // Ensure 2 decimal places
                  .replace(/\B(?=(\d{3})+(?!\d))/g, "'"); // Add single quotes as thousand separators
    
        // Update the form control value with the formatted value without emitting the event.
        this.insuranceForm.get(formControlName).setValue(formattedValue, { emitEvent: false });
    }

    onFocusRemoveFormat(formControlName: string): void {
        const control = this.insuranceForm.get(formControlName);
        if (control) {
            const numericValue = parseFloat(control.value?.toString().replace(/'/g, '') || '');
            control.setValue(isNaN(numericValue) ? '' : numericValue, { emitEvent: false });
        }
    }

    get submitText(): string {
        return this.existingInsurance
            ? this.translocoService.translate('admin.docUploads.save')
            : this.translocoService.translate('admin.docUploads.create');
    }

    get disabledEntitySelect(): boolean {
        return Boolean(this.selectedCoveredEntityId);
    }

    ngOnDestroy() {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}