import {
    Component,
    Input,
    OnInit,
    OnChanges,
    SimpleChanges,
    OnDestroy,
} from '@angular/core';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FormlyFieldConfig, FormlyModule } from '@ngx-formly/core';
import { FormlyMaterialModule } from '@ngx-formly/material';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatSelectModule } from '@angular/material/select';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatButtonModule } from '@angular/material/button';

import { TranslocoModule, TranslocoService } from '@ngneat/transloco';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

import { LangCode } from 'app/common/supabase-models/common';

import { PanelWrapperComponent } from './panel-wrapper.component';
import { DatepickerTypeComponent } from './datepicker.type';
import { RepeatTypeComponent } from './repeat.type';
import { YesNoTypeComponent } from './yesno.type';

import { convertJsonSchemaToFormlyFields } from './json-schema-to-formly';

@Component({
    selector: 'app-dynamic-form',
    standalone: true,
    imports: [
        CommonModule,
        ReactiveFormsModule,
        FormlyModule,
        FormlyMaterialModule,
        // Angular Material Modules
        MatFormFieldModule,
        MatInputModule,
        MatCheckboxModule,
        MatSelectModule,
        MatDatepickerModule,
        MatButtonModule,
        // Third-party libs
        TranslocoModule,
        // Custom Field Components
        DatepickerTypeComponent,
        PanelWrapperComponent,
        RepeatTypeComponent,
        YesNoTypeComponent,
    ],
    templateUrl: './dynamic-form.component.html',
    styleUrls: ['./dynamic-form.component.scss'],
})
export class DynamicFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() formGroup: FormGroup;
    @Input() optionsJsonSchemaMultilang: any;
    @Input() optionsUiSchema: any;
    @Input() initialValues: any;

    private _unsubscribeAll: Subject<any> = new Subject<any>();
    private selectedLanguage: LangCode = 'en';
    fields: FormlyFieldConfig[] = [];

    constructor(private translocoService: TranslocoService) {
        this.translocoService.langChanges$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((lang) => {
                this.selectedLanguage = lang.toLowerCase() as LangCode;
            });
    }

    ngOnInit() {
        this.initializeForm();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (
            changes.options ||
            changes.optionsJsonSchemaMultilang ||
            changes.optionsUiSchema ||
            changes.initialValues
        ) {
            this.initializeForm();
        }
    }

    private initializeForm() {
        const localizedOptionsJsonSchema = this.optionsJsonSchemaMultilang[this.selectedLanguage] || this.optionsJsonSchemaMultilang['en'];

        if (localizedOptionsJsonSchema) {
            // Convert schema to Formly fields
            this.fields = convertJsonSchemaToFormlyFields(
                localizedOptionsJsonSchema,
                this.optionsUiSchema
            );

            // Set initial values
            if (this.initialValues) {
                this.formGroup.patchValue(this.initialValues); // TODO: initial value does not support localization/multi-language
            } else {
                console.log('No initial values provided to DynamicFormComponent.');
            }
        } else {
            console.error('No optionsJsonSchemaMultilang provided to DynamicFormComponent.');
        }
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
