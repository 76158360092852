import { Injectable } from '@angular/core';
import { SupabaseClient } from '@supabase/supabase-js';
import { from, Observable, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { SupabaseClientService } from './supabase-client.service';

@Injectable({
    providedIn: 'root',
})
export class ObjectService {
    private supabase: SupabaseClient;

    constructor(private supabaseClientService: SupabaseClientService) {
        this.supabase = this.supabaseClientService.getClient();
    }

    /**
     * Retrieve all objects filtered by object_type_id and owner_id
     * @param objectTypeId The ID of the object type to filter by
     * @param ownerId The owner ID to filter by
     * @returns An Observable containing the list of objects
     */
    getAllByObjectTypeAndOwner(objectTypeId: number, ownerId: string): Observable<any[]> {
        return from(
            this.supabase
                .from('object')
                .select('*')
                .eq('object_type_id', objectTypeId)
                .eq('owner_id', ownerId)
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return response.data || [];
            }),
            catchError((error) =>
                throwError(() => new Error(`Failed to fetch objects: ${error.message}`))
            )
        );
    }

    /**
     * Save an object and optional subtype data
     * @param objectData Object data to save
     * @param subtypeData Optional subtype-specific data
     */
    saveObject(
        objectData: { object_type_id: number; name: string; dynatt: Record<string, any> },
        subtypeData?: { [key: string]: any }
    ): Observable<any> {
        return from(
            this.supabase
                .from('object_type')
                .select('is_all_dynamic, dynatt_schema')
                .eq('id', objectData.object_type_id)
                .single()
        ).pipe(
            switchMap((response) => {
                if (response.error) throw new Error(response.error.message);

                const { is_all_dynamic, dynatt_schema } = response.data;

                // Validate dynatt
                const validationError = this.validateDynatt(
                    objectData.dynatt,
                    dynatt_schema,
                    is_all_dynamic
                );
                if (validationError) return throwError(() => new Error(validationError));
                let savedObject;

                // Save the object
                return from(
                    this.supabase.from('object').insert([objectData]).select().single()
                ).pipe(
                    switchMap((objectResponse) => {
                        if (objectResponse.error)
                            throw new Error(objectResponse.error.message);

                        savedObject = objectResponse.data;

                        // If no subtypeData or is_all_dynamic is true, no further action required
                        if (is_all_dynamic || !subtypeData) return from([savedObject]);

                        // Save subtype-specific data
                        const subtypeTable = this.getSubtypeTable(
                            objectData.object_type_id
                        );
                        const subtypePayload = { id: savedObject.id, ...subtypeData };

                        return from(
                            this.supabase
                                .from(subtypeTable)
                                .insert([subtypePayload])
                                .select()
                                .single()
                        ).pipe(
                            map((subtypeResponse) => ({
                                object: savedObject,
                                subtype: subtypeResponse.data,
                            }))
                        );
                    }),
                    catchError((error) =>
                        // Rollback the saved object if the subtype save fails
                        from(
                            this.supabase.from('object').delete().eq('id', savedObject.id)
                        ).pipe(
                            switchMap(() => throwError(() => new Error(error.message)))
                        )
                    )
                );
            }),
            catchError((error) => throwError(() => new Error(error.message)))
        );
    }

    /**
     * Validate the dynatt field based on the provided schema
     * @param dynatt The dynamic attributes to validate
     * @param schema Validation schema from object_type.dynatt_schema
     * @param isAllDynamic Whether all attributes are stored in dynatt
     * @returns A string error message if validation fails, otherwise null
     */
    private validateDynatt(
        dynatt: Record<string, any>,
        schema: any,
        isAllDynamic: boolean
    ): string | null {
        if (!schema) return null; // No schema, no validation needed

        const requiredFields = schema.required || [];
        const optionalFields = schema.optional || [];
        const allowedFields = [...requiredFields, ...optionalFields];

        // Check for missing required fields
        const missingFields = requiredFields.filter((field) => !(field in dynatt));
        if (missingFields.length > 0) {
            return `Missing required fields: ${missingFields.join(', ')}`;
        }

        // Check for extra fields if isAllDynamic = false
        if (!isAllDynamic) {
            const extraFields = Object.keys(dynatt).filter(
                (field) => !allowedFields.includes(field)
            );
            if (extraFields.length > 0) {
                return `Invalid fields: ${extraFields.join(', ')}`;
            }
        }

        return null; // Validation passed
    }

    /**
     * Get the subtype table name based on the object type ID
     * @param objectTypeId The ID of the object type
     * @returns The name of the subtype table
     */
    private getSubtypeTable(objectTypeId: number): string {
        // Map object type ID to subtype table name
        const subtypeTableMap: { [key: number]: string } = {
            1: 'car', // Example: Object Type ID 1 corresponds to `car` table
            // Add more subtype mappings as needed
        };
        return subtypeTableMap[objectTypeId] || '';
    }


    /**
     * Update only the dynatt field for a specific object
     * @param objectId The ID of the object to update
     * @param dynatt The updated dynamic attributes
     * @returns An Observable containing the updated object
     */
    updateDynatt(objectId: number, dynatt: Record<string, any>): Observable<Object> {
        return from(
            this.supabase
                .from('object')
                .update({ dynatt })
                .eq('id', objectId)
                .select()
                .single()
        ).pipe(
            map((response) => {
                if (response.error) {
                    throw new Error(response.error.message);
                }
                return response.data as Object;
            }),
            catchError((error) =>
                throwError(() => new Error(`Failed to update dynatt: ${error.message}`))
            )
        );
    }
}
